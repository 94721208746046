<template>
  <div>
    <!--    <base-loader v-if="isLoading || isCreatingBalancer || dataLoading || isRequest" />-->
    <base-loader v-if="isNewBalancerLoading" />
    <div v-else class="stack-order page">
      <h3 class="page-title">
        {{ $t('title') }}
      </h3>
      <transition name="fade" mode="out-in">
        <div class="stack-order__content">
          <page-block title=" " size="bigger">
            <main-card class="stack-order__card">
              <div class="l-col">
                <base-input
                  v-model="name"
                  type="text"
                  :name="$t('balancer.name')"
                  :required="true"
                  use-reactive-validation
                  :label="$t('balancer.name')"
                  :hint="$t('balancer.hint')"
                  class="form__field--input"
                  @input="onChange"
                >
                </base-input>
              </div>
              <div class="l-col">
                <label class="typo__label standart-title">{{ $t('type') }}</label>
                <div class="l-col resize-flavor__config">
                  <div v-for="opt in navFlavor" :key="opt.k" class="resize-flavor__config">
                    <base-radio
                      v-model="flavor"
                      :value="opt.k"
                      theme="tile"
                      class="resize-flavor__config-item standart-title"
                    >
                      <label class="typo__label big-title">{{ opt.v }}</label>
                      <br />
                      <label class="typo__label standart-title">{{ opt.text }}</label>
                      <br />
                      <label class="typo__label standart-title">{{
                        $n(opt.cost, 'currency') + ' в час'
                      }}</label>
                    </base-radio>
                  </div>
                </div>
              </div>
              <div class="l-col">
                <base-select
                  v-model="subnet_id"
                  select-label=""
                  :hide-selected="true"
                  :searchable="false"
                  placeholder="Выберите подсеть"
                  :custom-label="subnetName"
                  :preselect-first="true"
                  :required="true"
                  :field-label="$t('subnet')"
                  :options="subnetPrivate"
                  size="medium"
                  :allow-empty="false"
                  class="new-disk__services"
                  @input="onChangeNetwork"
                >
                </base-select>
              </div>
              <div
                v-if="allFreePorts.length"
                :class="portId === 'Новый порт' ? 'el-col-8' : 'el-col-12'"
              >
                <base-select
                  v-model="portId"
                  select-label=""
                  :hide-selected="true"
                  :searchable="false"
                  placeholder="Выберите порт"
                  :preselect-first="true"
                  :custom-label="portName"
                  :required="true"
                  :field-label="$t('portAviable')"
                  size="medium"
                  :options="allFreePortsId"
                  :allow-empty="false"
                  class="new-disk__services"
                  @change="onChangeNetwork"
                >
                </base-select>
              </div>
              <div v-if="portId === 'Новый порт'" class="el-col-8">
                <base-input
                  v-model="buferIp"
                  :custom-error-messages="customValidationMsgs ? customValidationMsgs : null"
                  :label="$t('port')"
                  class="form__field--input"
                  type="ip"
                  use-reactive-validation
                  :required="true"
                  :pattern="patternIp"
                  @input="onChangeNetwork"
                >
                </base-input>
              </div>

              <div :class="portId === 'Новый порт' ? 'el-col-8' : 'el-col-12'">
                <div>
                  <base-select
                    v-if="isOk"
                    v-model="floatIp"
                    select-label=""
                    :hide-selected="true"
                    :searchable="false"
                    placeholder="Выберите порт"
                    :preselect-first="true"
                    :required="true"
                    :field-label="$t('public')"
                    size="medium"
                    :options="floatIpFree"
                    :allow-empty="false"
                    class="form__field--input"
                    track-by="floating_ip_address"
                    :custom-label="viewFloatingIp"
                    @change="onChangeNetwork"
                  >
                  </base-select>
                  <div v-else>
                    <base-input
                      v-model="notFloat"
                      :label="$t('public')"
                      class="form__field--input opacity"
                      :readonly="true"
                    >
                    </base-input>
                  </div>
                </div>
              </div>
            </main-card>
          </page-block>
        </div>
      </transition>
      <page-block>
        <div v-for="item in newRules" :key="item.id">
          <add-new-rule
            :id="item.id"
            :key="item.id"
            :balancer="true"
            :subnet-id="subnet_id"
            :protocol="usedBalancerPorts(item.id)"
            :rule="item"
            :created="false"
            @click-more="$emit('click-more', item)"
            @update-rule="updateRule(item.id, $event)"
            @add-new-rule="addNewRule"
            @remove-rule="deleteRule(item.id)"
          />
        </div>
      </page-block>
      <page-block v-if="newRules.length === 0" :title="' '" class="vps-detail-backup__block">
        <plain-button color="primary" nowrap @click="addRule">
          {{ $t('newRule') }}
        </plain-button>
      </page-block>
      <div v-if="!isLoading" class="typo__stack-order page">
        <div class="stack-order__content">
          <transition name="fade" mode="out-in">
            <page-block :title="$t('additional')" size="bigger">
              <div class="l-flex-gutter-20">
                <div class="l-col-12_ml-8">
                  <main-card class="stack-order__card">
                    <label class="typo__label standart-title">{{ $t('warnings') }}</label>
                    <transition name="fade" mode="out-in">
                      <page-block v-if="configToCost">
                        <ul class="order-summary__list section-base">
                          <li class="order-summary__item">
                            <div class="order-summary__label standart-title">
                              <label>Наименование</label>
                            </div>
                            <!--                            <div class="order-summary__value standart-title">Количество в час</div>-->
                            <div class="order-summary__price-center standart-title">
                              {{ `Цена за ${periodLabel}` }}
                            </div>
                          </li>
                          <li
                            v-for="(item, i) in viewConfigCost"
                            :key="i"
                            class="order-summary__item"
                          >
                            <div class="order-summary__label standart-text">
                              {{ $t(`summaryLabels.${item.name}`) }}
                            </div>
                            <!--                            <div class="order-summary__value standart-text">-->
                            <!--                              {{ item.count }}-->
                            <!--                            </div>-->
                            <div class="order-summary__price-center standart-text">
                              {{ $n(item.price * item.count * period, 'currency') }}
                            </div>
                          </li>
                        </ul>
                      </page-block>
                    </transition>
                    <template #footer>
                      <div class="stack-order__footer">
                        <div class="stack-order__price">
                          <span class="stack-order__sum bigger-heading">
                            Стоимость балансировщика {{ $n(cost * period, 'currency') }} в
                          </span>
                          <base-select
                            :value="periodValue"
                            :options="priceByPeriod"
                            :searchable="false"
                            :allow-empty="false"
                            label="label"
                            :show-labels="false"
                            class="vps-order__period"
                            @input="onPeriodChange"
                          />
                        </div>
                        <div class="stack-order__actions">
                          <base-button
                            :loading="isCreatingBalancer"
                            :disabled="
                              isCalculating || !isShowCreateButton || patternIp === 'tooMatch'
                            "
                            class="stack-order__btn"
                            @click="newBalancer"
                          >
                            {{ $t('newBalancer') }}
                          </base-button>
                        </div>
                      </div>
                    </template>
                  </main-card>
                </div>
              </div>
            </page-block>
          </transition>
        </div>
      </div>
      <!--      <page-block :title="' '" class="vps-detail-backup__block">-->
      <!--        <base-button-->
      <!--          -->
      <!--          :loading="isCreatingBalancer"-->
      <!--          :disabled="!isShowCreateButton"-->
      <!--          color="primary"-->
      <!--          @click="newBalancer"-->
      <!--        >-->
      <!--          {{ $t('newBalancer') }}-->
      <!--        </base-button>-->
      <!--      </page-block>-->
    </div>
  </div>
</template>

<script>
import BaseLoader from '@/components/BaseLoader/BaseLoader';
import BaseInput from '@/components/BaseInput/BaseInput';
import BaseRadio from '@/components/Checkboxes/BaseRadio';
import BaseSelect from '@/components/Select/BaseSelect';
import networks from '@/layouts/Stack/mixins/networks';
import storeMixin from '@/layouts/Stack/mixins';
import AddNewRule from '@/layouts/Stack/pages/Main/components/AddNewRule';
import BaseButton from '@/components/Buttons/BaseButton';
import showErrorModal from '@/mixins/showErrorModal';
import attachFloatingIp from '@/layouts/Stack/mixins/attachFloatingIp';
import { IS_PROD } from '@/layouts/LoginPage/mixins/currentProvider';

export default {
  name: 'NewBalancer',
  components: {
    BaseLoader,
    BaseRadio,
    BaseSelect,
    BaseInput,
    BaseButton,
    AddNewRule,
  },
  mixins: [networks, storeMixin, showErrorModal],
  data() {
    return {
      customValidationMsgs: {
        confirm: { patternMismatch: this.$t('error') },
      },
      name: 'Балансировщик',
      isCreatingBalancer: false,
      isCalculating: false,
      notFloat: 'Подключите подсеть к роутеру',
      period: 1,
      cost: 0,
      costFloatingIp: 0,
      costBalancer: 0,
      configToCost: {},
      baseRule: {
        id: 1,
        ssl: {},
        description: '',
        members: [],
        pool: {},
        insert_headers: {},
        healthmonitor: {},
        listener: {},
        headers: {},
        isSsl: false,
        isHeaders: false,
        protocolRules: ['TCP', 'UDP', 'HTTP', 'TERMINATED_HTTPS'],
        server: {
          port: 80,
          protocol: 'TCP',
        },
        protocol: ['TCP', 'UDP', 'HTTP', 'TERMINATED_HTTPS'],
        protocolServers: ['TCP', 'PROXY'],
        name: 'Rule#' + 1,
        balancer: {
          port: 80,
          protocol: 'TCP',
        },
        selected: 'RuleServers',
      },
      ipAddress: '',
      rule: {
        server: {
          port: 80,
        },
        balancer: {
          port: 80,
        },
      },
      newRules: [],
      formData: {},
      value: '',
      isOk: false,
      isNewBalancerLoading: true,
      mask: '',
      timerCnt: 0,
      active: 0,
      networkWithSubnet: [],
      port: {},
      listIp: [],
      patternIp: '',
      newIp: [],
      floatIp: {},
      pullIp: [],
      reserveIp: [],
      lastIpSection: '',
      useIp: [],
      all: [],
      buferIp: '',
      protocolRules: ['TCP', 'UDP', 'HTTP', 'TERMINATED_HTTPS'],
      portId: 'Новый порт',
      portForBalancer: '',
      subnet_id: '',
      network_id: '',
      flavor: '1',
      // navFlavor: [
      //   {
      //     v: this.$t('nav.base'),
      //     k: '1',
      //     text: 'без резервирования',
      //     cost: 10,
      //     // text: 'HTTP-запросы — 19,5 тыс. RPS, HTTPS — 3 тыс. RPS',
      //   },
      //   {
      //     v: this.$t('nav.advanced'),
      //     k: '2',
      //     text: 'с резервированием',
      //     cost: 20,
      //     // text: 'HTTP-запросы — 19,5 тыс. RPS, HTTPS — 3 тыс. RPS',
      //   },
      // ],
    };
  },
  computed: {
    isRequest() {
      return this.$store.state.moduleStack.serverRequest;
    },
    // patternIP() {
    //   return this.isIPv4(this.mask) &&
    //     this.lastIpSection === 0 &&
    //     this.subnetAddr > 23 &&
    //     this.subnetAddr < 30
    //     ? `${this.mask}${this.lastIpSection}/${this.subnetAddr}`
    //     : 'null';
    // },
    periodLabel() {
      return this.priceByPeriod.find(i => i.value === this.period).label.toLowerCase();
    },
    dev() {
      return IS_PROD ? 'stat' : 'full_price';
    },
    navFlavor() {
      return [
        {
          v: this.$t('nav.base'),
          k: '1',
          text: 'без резервирования',
          cost: this.costBaseBalancer,
          // text: 'HTTP-запросы — 19,5 тыс. RPS, HTTPS — 3 тыс. RPS',
        },
        {
          v: this.$t('nav.advanced'),
          k: '2',
          text: 'с резервированием',
          cost: this.costAdvancedBalancer,
          // text: 'HTTP-запросы — 19,5 тыс. RPS, HTTPS — 3 тыс. RPS',
        },
      ];
    },

    // costBalancer() {
    //   return 10;
    // },
    // costFloatIp() {
    //   return 20;
    // },
    priceByPeriod() {
      return [
        {
          label: this.$t('hour'),
          value: 1,
        },
        {
          label: this.$t('day'),
          value: 24,
        },
        {
          label: this.$t('month'),
          value: 24 * 30,
        },
      ];
    },
    periodValue() {
      return this.priceByPeriod.find(i => i.value === +this.period);
    },
    viewConfigCost: {
      get() {
        return this.configToCost;
      },
      set(value) {
        // console.log(value);
        return (this.configToCost.network.value = this.backupNetworkDisk.networkDisk
          ? this.configToCost.network.value
          : this.configToCost.network.value);
      },
    },
    dataLoading() {
      return !this.subnet_id && !this.filtredSub.length;
    },
    flavors() {
      return this.$store.state.moduleStack.flavorprofiles;
    },
    allocation_pools() {
      return this.network_id
        ? this.$store.state.moduleStack.subnets.find(x => x.network_id === this.network_id)
            .allocation_pools
        : null;
    },
    allFreePorts() {
      if (this.network_id) {
        return this.$store.state.moduleStack.ports
          .filter(x => x.network_id === this.network_id)
          .filter(x => !x.device_id);
      } else return [];
    },
    isShowCreateButton() {
      return this.newRules && this.newRules.length > 0
        ? this.newRules[0].isShowButton && Object.keys(this.floatIp).length > 0
        : false;
    },
    ports() {
      return this.$store.state.moduleStack.ports
        .filter(x => x.network_id === this.network_id)
        .map(x => x.fixed_ips)
        .flat()
        .filter(x => x.subnet_id === this.subnet_id)
        .map(x => x.ip_address);
    },
    currentPort() {
      return this.$store.state.moduleStack.ports.find(x => x.id === this.port);
    },
    getPrice() {
      return this.$store.state.moduleStack.price.length
        ? this.$store.state.moduleStack.price
        : null;
    },
    floatIpCost() {
      return this.getPrice ? this.getPrice.find(x => x.intname === 'floating_ip')[this.dev] : 0;
    },
    costBaseBalancer() {
      return this.getPrice
        ? this.getPrice.find(x => x.intname === 'loadbalancer_SINGLE')[this.dev]
        : 0;
    },
    costAdvancedBalancer() {
      return this.getPrice
        ? this.getPrice.find(x => x.intname === 'loadbalancer_ACTIVE_STANDBY')[this.dev]
        : 0;
    },
    allFreePortsId() {
      if (this.allFreePorts.length && !this.isNewBalancerLoading) {
        const ports = this.allFreePorts.length ? this.allFreePorts.map(x => x.id) : [];
        const toCreatePorts = this.availableCreatedPorts(this.subnet_id);
        if (ports.length && toCreatePorts > 0) ports.push('Новый порт');
        return this.allFreePorts.length ? ports : [];
      } else return [];
    },
    floatIpFree() {
      const ip = this.$store.state.moduleStack.floatingips.filter(port => !port.fixed_ip_address);
      if (ip.length) ip.map(x => x.floating_ip_address);
      return ip;
    },
    length() {
      return !!this.newRules.length ? this.newRules.length + 1 : 1;
    },
    isAddedMember() {
      return (
        this.newRules.length > 0 && this.newRules[0].members && this.newRules[0].members.length > 0
      );
    },
    summaryCost() {
      let sum = 0;
      if (this.flavor === '1' && Object.keys(this.floatIp).length === 0) {
        sum = this.costBaseBalancer;
      } else if (this.flavor === '2' && Object.keys(this.floatIp).length === 0) {
        sum = this.costAdvancedBalancer;
        // } else if (this.flavor === '1' && Object.keys(this.floatIp)) {
        //   sum = this.costBaseBalancer + this.floatIpCost;
        // } else if (this.flavor === '2' && Object.keys(this.floatIp)) {
        //   sum = this.costAdvancedBalancer + this.floatIpCost;
      }
      return sum || 0;
    },
  },
  watch: {
    isNewBalancerLoading: {
      handler: function (event) {
        if (!event) {
          if (
            this.subnet_id &&
            this.networkWithSubnet.filter(x => x.subnet === this.subnet_id).length > 0
          ) {
            this.isOk = true;
          } else {
            this.floatIp = {};
            this.isOk = false;
          }
        }
      },
    },
    costBaseBalancer(event) {
      if (event) this.configToCost.balancer.price = event;
    },
    summaryCost: {
      handler: function (val) {
        this.cost = val;
        // if (!!Object.keys(this.floatIp).length) {
        //   this.configToCost.floatIp = {
        //     name: 'floatIp',
        //     count: 1,
        //     price: this.floatIpCost,
        //   };
        // }
      },
      immediate: true,
    },
    flavor: {
      handler: function (event) {
        if (event === '1') {
          this.configToCost.balancer = {
            name: 'baseBalancer',
            count: 1,
            price: this.costBaseBalancer,
          };
        } else {
          this.configToCost.balancer = {
            name: 'advancedBalancer',
            count: 1,
            price: this.costAdvancedBalancer,
          };
        }
      },
      immediate: true,
    },
    listIp: function (event) {
      this.mask = '';
      this.value = '';
      this.patternIp = this.value;
      this.newIp = [];
      if (event.length) {
        this.all = this.getAllPorts(
          this.getLastIpSection(this.allocation_pools[0].start),
          this.getLastIpSection(this.allocation_pools[0].end)
        );

        this.useIp = this.ports.map(x => this.getLastIpSection(x));
        this.newIp = this.all.filter(x => !this.useIp.includes(x));
        this.reserveIp = this.getLastIpSection(this.allocation_pools[0].end) + 1;
        const ip = this.newIp.length > 0 ? this.newIp[0] : null;
        this.mask = this.getMask(this.allocation_pools[0].end);
        if (ip && this.newIp[0]) {
          this.value = this.mask + this.newIp[0];
          this.patternIp = this.value;
        }
        this.buferIp = this.value.length > 0 ? this.value : this.$emit('notready');
      } else {
        this.pullIp = [
          this.getLastIpSection(this.allocation_pools[0].start),
          this.getLastIpSection(this.allocation_pools[0].end),
        ];
        this.pullIp.forEach((x, i) => {
          if (this.pullIp[i + 1] - this.pullIp[i] > 1 && i - 1 !== this.pullIp.length) {
            this.newIp.push(this.pullIp[i]);
          }
        });
        const ip = this.allocation_pools[0].start;
        this.mask = this.getMask(ip);
        if (ip && this.newIp[0]) {
          this.value = this.mask + this.newIp[0];
          this.patternIp = this.value;
        }
        this.buferIp = this.value.length > 0 ? this.value : this.$emit('notready');
        return console.log('список пуст!');
      }
    },
    network_id(event) {
      if (event && this.portId === 'Новый порт') {
        this.port.network_id = event;
      }
    },
    subnet_id: {
      handler: function (event) {
        if (event && this.networkWithSubnet.filter(x => x.subnet === event).length > 0) {
          this.isOk = true;
        } else {
          this.floatIp = {};
          this.isOk = false;
        }

        if (event) {
          this.onChangeNetwork(event);
          this.network_id = this.$store.state.moduleStack.subnets.find(
            x => x.id === event
          ).network_id;
        }
        if (event && this.portId === 'Новый порт') {
          this.port.network_id = event;
        }
      },
      immediate: false,
    },
    portId: {
      handler: function (event) {
        if (event && event === 'Новый порт') {
          this.port = {
            network_id: this.network_id,
            fixed_ips: [
              {
                subnet_id: this.subnet_id,
                ip_address: this.buferIp,
              },
            ],
          };
        } else if (event && event !== 'Новый порт') {
          this.ipAddress = this.$store.state.moduleStack.ports.find(
            port => port.id === event
          ).fixed_ips[0].ip_address;
        } else {
        }
        this.ipAddress = '';
        this.port = {};
      },
      immediate: true,
    },
    patternIt(event) {
      // console.log('value', event);
    },
  },
  async mounted() {
    await this.fetchNewBalancer()
      .then(() => {
        this.subnet_id = this.filtredSub[0];
      })
      .then(() => {
        this.$store.state.moduleStack.ports
          .filter(x => x.device_owner === 'network:router_interface')
          .forEach(x => {
            this.networkWithSubnet.push({ net: x.network_id, subnet: x.fixed_ips[0].subnet_id });
          });
      })
      .then(() => {
        this.addRule();
      })
      .then(() => (this.isNewBalancerLoading = false));
  },
  methods: {
    usedBalancerPorts(id) {
      if (this.newRules.length > 1) {
        return this.newRules.filter(x => x.id < id).map(x => x.balancer.port);
      } else return [];
    },
    isIPv4(addr) {
      return /^(([01]?\d{1,2}|2[0-4]\d|25[0-5])(\.|$)){3}$/.test(addr);
    },
    getMask(ip) {
      return ip.slice(0, ip.lastIndexOf('.') + 1);
    },
    floatIpName(event) {
      return event;
    },
    addRule() {
      const id = this.newRules.length ? this.newRules.at(-1).id : 0;
      const rule = Object.assign({}, this.baseRule);
      rule.id = id + 1;
      rule.name = 'Rule#' + (id + 1);
      this.newRules.push(rule);
    },
    deleteRule(id) {
      // console.log('deleteRule', id);
      return (this.newRules = this.newRules.filter(x => x.id !== id));
    },
    updateRule(id, event) {
      // console.log('updateRule', id, event);
      this.newRules = this.newRules.map(element => {
        if (element.id === id) {
          return event;
        }
        return element;
      });
    },
    async addNewRule() {
      this.isCurrentLoading = true;
      const rule = this.newRules[0];
      const listener = await this.addNewListener();
    },
    availableCreatedPorts(net) {
      const arr = [];
      const subnets = this.subnet;
      subnets.forEach(net => {
        arr.push(this.getAvailablePorts(net));
      });
      return arr.find(x => x.id === net).ports;
    },
    viewFloatingIp({ floating_ip_address }) {
      return floating_ip_address
        ? // ? `${floating_ip_address} (${this.$n(this.floatIpCost, 'currency')} в час)`
          floating_ip_address
        : '';
      // return `${floating_ip_address} - ()` || '';
    },
    onChange(event) {
      // console.log(event);
    },
    onPeriodChange(obj) {
      this.period = obj.value;
      // this.calcSum();
    },
    onChangeNetwork(event) {
      // console.log(event);
      if (this.subnet.includes(event)) {
        this.network_id = this.$store.state.moduleStack.networks.find(x =>
          x.subnets.find(i => i === event)
        ).id;
        this.portId = 'Новый порт';
      } else if (this.allFreePortsId.includes(event)) {
        this.portId = event;
        this.value = '';
        this.patternIp = this.value;
      }
      if (this.subnet.includes(event)) {
        this.networkId = event;
        const port = this.$store.state.moduleStack.ports;
        const updatePort = port.map(x => x.fixed_ips.filter(a => a.subnet_id === event));
        const formatPort = updatePort.reduce((acc, item) => {
          if (item !== []) acc.push(item);
          return acc;
        }, []);
        this.listIp = formatPort
          .filter(x => x[0])
          .flat()
          .map(x => x.ip_address)
          .sort((a, b) => a.split('.').at(-1) - b.split('.').at(-1));
      } else {
        // console.log('else');
        // console.log(this.newIp);
        const ip = this.getLastIpSection(event);
        // console.log('ip', ip);
        if (this.newIp.includes(ip)) {
          // console.log('includes');
          this.value = event;
          this.patternIp = this.value;
        } else {
          this.patternIp = 'tooMatch';
          // console.log('notincludes');
        }
      }
    },
    async updatePort(port) {
      const params = {
        port: {
          device_owner: 'Octavia',
        },
        id: port,
        item: 'device_owner',
      };
      return await this.$store.dispatch('moduleStack/updatePort', params).catch(e => {
        this.showError(e);
      });
    },
    async updatePortBalancer(port) {
      const params = {
        port: {
          device_id: 'lb-' + port,
        },
        id: this.portId === 'Новый порт' ? this.portForBalancer : this.portId,
        item: 'device_id',
      };
      return await this.$store.dispatch('moduleStack/updatePort', params).catch(e => {
        this.showError(e);
      });
    },
    async attachFloatingIpToPort(id, port, ip) {
      const params = {
        port: port, // id порта
        id: id, // id порта плавающего
        ip: ip, //ip-адрес
      };
      return this.$store.dispatch('moduleStack/attachFloatingIpToPort', params).catch(e => {
        this.showError(e);
      });
    },
    async createPortCurrentNetwork(port) {
      const params = {
        port: port,
      };
      let portId = '';
      const port_id = await this.$store
        .dispatch('moduleStack/createPort', params)
        .then(async data => {
          portId = data.port.id;
          await this.$store.dispatch('moduleStack/fetchOpenStackRequest', 'ports');
          return data.port.id;
        })
        .catch(e => {
          this.showError(e);
        });
      return portId;
    },
    updateCreatedBalancerStatus(callback, id) {
      if (this.timerCnt < 5) this.timerCnt += 1;
      this.timerId = setTimeout(() => {
        this.$store
          .dispatch('moduleStack/checkBalancerStatus', {
            id: id,
          })
          .then(async data => {
            if (data.data.provisioning_status === 'PENDING_CREATE') {
              this.isCreatingBalancer = true;
              await this.updateCreatedBalancerStatus(callback, id);
            } else {
              this.timerCnt = 0;
              if (callback && typeof callback === 'function') callback();
              this.isCreatingBalancer = false;
              clearTimeout(this.timerId);
            }
          });
      }, 1000 * this.timerCnt);
    },
    async newBalancer() {
      // console.log('newBalancer');
      this.isCreatingBalancer = true;
      if (this.portId === 'Новый порт') {
        this.port = {
          network_id: this.network_id,
          fixed_ips: [
            {
              subnet_id: this.subnet_id,
              ip_address: this.buferIp,
            },
          ],
        };

        let port = '';
        await this.createPortCurrentNetwork(this.port).then(data => {
          port = data;
        });
        // console.log(port);
        this.portForBalancer = port;
        await this.updatePort(port);
        await this.attachFloatingIpToPort(this.floatIp.id, port);
        const loadbalancer = {
          name: this.name,
          vip_subnet_id: this.subnet_id,
          vip_port_id: port,
          vip_address: this.buferIp,
          flavor_id: this.flavor === '1' ? this.flavors.base : this.flavors.advanced,
        };
        const listeners = [];
        this.newRules.forEach(listener => {
          const rule = {
            insert_headers: Object.assign({}, listener.insert_headers),
            name: listener.name,
            protocol:
              listener.balancer.protocol === 'HTTPS'
                ? 'TERMINATED_HTTPS'
                : listener.balancer.protocol,
            protocol_port: +listener.balancer.port,
            ...listener.listener,
          };
          if (listener.isSsl) {
            rule.default_tls_container_ref = listener.ssl.container_ref;
          }
          if (listener.isHeaders) {
            rule.insert_headers = Object.assign({}, listener.insert_headers);
          }
          rule.default_pool = {
            lb_algorithm: listener.pool.lb_algorithm,
            session_persistence: Object.keys(listener.pool.session_persistence).length
              ? listener.pool.session_persistence
              : null,
            name: listener.name,
            // protocol: listener.balancer.protocol === 'HTTPS' ? 'HTTP' : listener.balancer.protocol,
            protocol:
              listener.balancer.protocol === 'HTTPS'
                ? 'HTTP'
                : listener.protocolServer && listener.protocolServer[0]
                ? listener.protocolServer[0]
                : listener.balancer.protocol,
            healthmonitor: {
              ...listener.healthmonitor,
            },
            members: listener.members,
          };
          listeners.push(rule);
        });
        loadbalancer.listeners = listeners;
        // console.log(loadbalancer);
        this.$store
          .dispatch('moduleStack/createBalancer', { loadbalancer: loadbalancer })
          .then(async data => {
            // console.log(data.loadbalancer.id);
            await this.updatePortBalancer(data.loadbalancer.id);
            await this.$store.dispatch('moduleStack/fetchOpenStackRequest', 'loadbalancers');
            await this.updateCreatedBalancerStatus(_, data.loadbalancer.id);
          })
          .catch(error => {
            this.showError(error);
            console.error(error);
          })
          .finally(() => this.$router.push({ name: 'viewBalancer' }));
      } else {
        await this.attachFloatingIpToPort(this.floatIp.id, this.portId);
        const loadbalancer = {
          name: this.name,
          vip_subnet_id: this.subnet_id,
          vip_port_id: this.portId,
          vip_address: this.ipAddress,
          flavor_id: this.flavor === 1 ? this.flavors.base : this.flavors.advanced,
        };
        const listeners = [];
        this.newRules.forEach(listener => {
          const rule = {
            name: listener.name,
            // protocol: listener.balancer.protocol,
            protocol: listener.balancer.protocol,
            protocol_port: listener.balancer.port,
            ...listener.listener,
          };
          if (listener.isHeaders) rule.insert_headers = Object.assign({}, listener.insert_headers); //else rule.insert_headers = {};
          rule.default_pool = {
            lb_algorithm: listener.pool.lb_algorithm,
            session_persistence: Object.keys(listener.pool.session_persistence).length
              ? listener.pool.session_persistence
              : null,
            name: listener.name,
            protocol:
              listener.balancer.protocol === 'HTTPS'
                ? 'HTTP'
                : listener.protocolServer && listener.protocolServer[0]
                ? listener.protocolServer[0]
                : listener.balancer.protocol,
            // protocol: listener.balancer.protocol,
            healthmonitor: {
              ...listener.healthmonitor,
            },
            members: listener.members,
          };
          listeners.push(rule);
        });
        loadbalancer.listeners = listeners;
        // console.log(loadbalancer);
        this.$store
          .dispatch('moduleStack/createBalancer', { loadbalancer: loadbalancer })
          .then(async data => {
            await this.updatePortBalancer(data.loadbalancer.id);
            // console.log(data.loadbalancer.id);
            await this.$store.dispatch('moduleStack/fetchOpenStackRequest', 'loadbalancers');
            await this.updateCreatedBalancerStatus(_, data.loadbalancer.id);
          })
          .catch(error => {
            this.showError(error);
            console.error(error);
          })
          .finally(() => this.$router.push({ name: 'viewBalancer' }));
      }
    },
  },
};
</script>
<i18n>
{
  "ru": {
    "title": "Новый балансировщик нагрузки",
    "error": "Недопустимый IP-адрес",
    "type": "Тариф балансировщика",
    "day": "День",
    "hour": "Час",
    "month": "Месяц",
    "subnet": "Подсеть",
    "attachTo": "Подключить",
    "newRule": "Добавить правило",
    "newBalancer": "Создать балансировщик",
    "addRule": "Добавить еще правило",
    "port": "IP балансировщика",
    "portAviable": "IP балансировщика",
    "public": "Подключить плавающий IP",
    "warnings": "Обращаем ваше внимание на то, что расходы формируются раз в сутки, а счёт выставляется за фактически отработанное время. Сами расчёты осуществляются каждые 5 минут. \n Представленные ниже цены рассчитаны исходя из того, что сервер проработает полный период: час, день, месяц (30 дней).",

    "nav": {
      "base": "Базовый",
      "advanced": "Расширенный"
    },
    "summaryLabels": {
      "floatIp": "Плавающий IP, шт",
      "baseBalancer": "Базовый балансировщик",
      "advancedBalancer": "Балансировщик с резервированием"
    },
    "additional": "Стоимость",
    "balancer" : {
      "name" : "Имя балансировщика",
      "title" : "Правило",
      "hint" : "Имя балансировщика нагрузки"
    }
  }
}
</i18n>

<style lang="stylus" scoped>
@require '~@/assets/styles/vars/variables';
@require '~@/assets/styles/mixins/mixins';

.opacity {
  opacity: 1
}
.resize-flavor {

  &__config {
    display: flex;
    margin-top: 1rem;
    justify-content: space-around;
    text-align: left;

    &-item {
      min-width: 10rem;
      font-size: 20px;
      line-height: 26px;

      +breakpoint(sm-and-up) {
        min-width: 10rem;

      }
    }
  }
}
.stack-order {
  &__loading {
    flexy(center, center);
    flex: 1 1 100%;
  }
  &__sum {
    flex: 0 0 100%;

    +breakpoint(ms-and-up) {
      flex: 0 0 auto;
      margin-right: 0.5rem;
    }
  }
  &__price {
    flexy(flex-start, center, wrap);

    &-text {
      margin-right: 0.5rem;

      +breakpoint(sm-and-up) {
        font-size: $font-size-bigger;
      }
    }
  }
  &__period {
    flex: 0 0 8.3rem;

    +breakpoint(sm-and-up) {
      flex: 0 0 10rem;
    }
  }

  &__actions {
    margin: 0.75rem -0.75rem -0.75rem;
    flexy(flex-start, center, wrap);
  }

  &__btn {
    margin: 0.75rem;

    +breakpoint(xs-only) {
      flex: 1 1 100%;
    }
  }
}
.order-summary {
  &__list {
    no-style-list();
  }
  &__item {
    +breakpoint(sm-and-up) {
      flexy(flex-start, baseline);
    }
    & + & {
      //margin-top: 1.25rem;
      +breakpoint(sm-and-up) {
        margin-top: 0.25rem;
      }
    }
  }
  &__label {
    +breakpoint(sm-and-up) {
      flex: 0 0 45%;
      margin-right: 1.5rem;
    &-center {
      text-align: center
    }
    }
  }
  &__value {
    +breakpoint(sm-and-up) {
      flex: 0 0 25%;
      margin-right: 1.5rem;
      &-center {
      text-align: center
    }
    }
  }
  &__price {
    +breakpoint(sm-and-up) {
      flex: 0 0 25%;
      margin-right: 1.5rem;
      &-center {
      text-align: center
    }
    }
  }
}
</style>
